<template>
  <div>

    <v-alert
      class="error white--text"
      v-if="!isLinked"
    >
      The application is not linked to the QC System
    </v-alert>

  </div>
</template>

<script>

import QCSystemLinkHelper from "@/classes/QCSystemLinkHelper";


export default {

  mounted ()
  {
    this.checkIsLinked();
  },

  methods:
  {
    async checkIsLinked ()
    {
      let helper = new QCSystemLinkHelper();
      this.isLinked = await helper.isSystemLinked();
    }
  },

  data: () =>
  {

    return {
      isLinked: false,
    }
  }

}

</script>